// 实验模板 接口
import  request from "../utils/request";

// 查询我创建或参与的项目
export const getMyProjectList = (params) => {
    return request({
        method: 'get',
        url: `/project/getmycreateproject`,
        params
    })
}

// 查询项目下的所有试验记录
export const getProjectExperimentList = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectexperiment`,
        params
    })
}

// 查询项目顶部操作
export const getProjectSet = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectset`,
        params
    })
}

// 查询项目相关操作消息
export const getProjectNotice = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectnotice`,
        params
    })
}

// 添加项目
export const addProject = (data) => {
    return request({
        method: 'post',
        url: `/project/addproject`,
        data
    })
}

// 邀请项目成员
export const inviteUser = (data) => {
    return request({
        method: 'post',
        url: `/project/inviteuser`,
        data
    })
}

// 邀请成员 --- 查询项目组成员信息
export const getProjectUserManager = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectusermanager`,
        params
    })
}
// 移交项目负责人
export const handOverHead = (params) => {
    return request({
        method: 'post',
        url: `/project/handoverHead`,
        params
    })
}

// 设置审核员
export const setAssistant = (params) => {
    return request({
        method: 'post',
        url: `/project/setassistant`,
        params
    })
}

// 查询审核员名单
export const getProjectAudit = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectaudit`,
        params
    })
}

// 查询项目负责人名单
export const getProjectHeadList = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojecthead`,
        params
    })
}

// 查出授权人员名单列表
export const getAuthorizationUserList = (params) => {
    return request({
        method: `get`,
        url: `/project/getauthorizationuser`,
        params
    })
}

// 实验记录授权用户
export const postauthourizationUser = (data) => {
    return request({
        method: 'post',
        url: `/project/authorizationUser`,
        data
    })
}

// 查询单个项目信息
export const getProjectDetail = (params) => {
    return request({
        method: 'get',
        url: `/project/getprojectbyid`,
        params
    })
}

// 更新项目信息
export const updateProjectInfo = (data) => {
    return request({
        method: 'post',
        url: `/project/updateprojectinfo`,
        data
    })
}

// 修改项目名称
export const editProjectName = (params) => {
    return request({
        method: 'post',
        url: `/project/editProjectName`,
        params
    })
}

// 项目中心--诚信分申诉列表
export const getAllAppeal = (params) => {
    return request({
        method: 'get',
        url: `/projectreplygoodfaithappeal/getallappeal`,
        params
    })
}

// 返回这条诚信申请的相关申诉内容和回复记录
export const getGoodFaithClauseAppealContext = (params) => {
    return request({
        method: 'get',
        url: `/projectreplygoodfaithappeal/getgoodfaithclauseappealcontext`,
        params
    })
}

// 回复申诉
export const replyGoodFaithAppeal = (data) => {
    return request({
        method: 'post',
        url: `/projectreplygoodfaithappeal/reply`,
        data,
        timeout: 36000
    })
}

// 删除项目
export const cancelProject = (params) => {
    return request({
        method: 'get',
        url: '/project/cancelProject',
        params
    })
}

// 查询课题组列表
export const getResearchGroupList = () => {
    return request({
        method: 'get',
        url: '/project/getResearchGroupList'
    })
}

// 查询课题组成员
export const getResearchGroupUserList = (params) => {
    return request({
        method: 'get',
        url: `/project/getResearchGroupUserList`,
        params
    })
}

// 获取课题组中可加入的成员列表
export const getProjectUserByResearchGroupUser = (params) => {
    return request({
        method: 'get',
        url: `/project/getProjectUserByResearchGroupUser`,
        params
    })
}

// 选择课题组成员加入项目组
export const addResearchGroupMemberToProject = (data) => {
    return request({
        method: 'post',
        url: '/project/joinProject',
        data
    })
}