<template>
<div class="image-with-preview-ctn" :style="{width: `${width + 16}px`, height: `${width + 16}px` }">
    <div class="image-border-ctn">
        <div class="image-inner-ctn" :style="{width: `${width}px`, height: `${width}px` }" @click="previewModalVisible = true;">
            <img class="image" style="object-fit: fill;" title="查看大图" :src="src"/>
        </div>
    </div>
    <a-modal :visible.sync="previewModalVisible" :title="null" :footer="null" @cancel="previewModalVisible = false;">
        <img alt="example" style="width: 100%" :src="src" />
    </a-modal>
</div>
</template>

<script>
import { ref } from 'vue-demi';
import NotImage from '../assets/not.jpg';
export default {
    props: {
        src: {
            type: String,
            default: NotImage
        },
        width: {
            type: Number,
            default: 70
        }
    },
    setup(props, context) {
        const previewModalVisible = ref(false);
        return {
            previewModalVisible
        }
    }
}
</script>

<style lang="less" scoped>
.image-with-preview-ctn {
    margin: 0 8px 8px 0;
    .image-border-ctn {
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        position: relative;
        .image-inner-ctn {
            overflow: hidden;
            cursor: zoom-in;
            overflow: hidden;
            background: black;
            .image {
                object-fit: fill;
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>