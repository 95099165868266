<template>
    <div class="good-faith-audit">
        <div class="good-faith-audit-title">
            诚信记录
        </div>
        <div class="search-panel">
            <div class="search-option">
                <a-input v-model="DeductedUserName" placeholder="被扣分人" allowClear></a-input>
            </div>
            <div class="search-option">
                <a-input v-model="AduitUserName" placeholder="扣分人" allowClear></a-input>
            </div>
            <div class="search-option">
                <a-input v-model="ExperimentNo" placeholder="实验编号"></a-input>
            </div>
            <div class="search-option">
                <a-select placeholder="流程状态" v-model="AppealStatus" allowClear style="width: 100%;">
                    <a-select-option v-for="(item, index) in goodFaithAppealTypes" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-operation">
                <a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
                <!-- <a-button class="reset-btn" @click="resetSearch"> 重置 </a-button> -->
            </div>
        </div>
        <div class="table-ctn">
            <a-table rowKey="id" :columns="columns" :data-source="list" :pagination="false" size="small"
                class="exper-table" :scroll="{ x: 1100 }" :loading="loading ">
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="appealStatus" slot-scope="text, record, index">
                    <span :style="{color: getGoodFaithAppealTypeColor(record.appealStatus)}">
                    {{ getGoodFaithAppealTypeName(record.appealStatus) }}
                    </span>
                </template>
                <template slot="operation" slot-scope="text, record, index">
                    <a class="deal-btn" v-if="record.appealStatus === 1" @click="handleAppeal(record)">
                        处理
                    </a>
                    <a v-else @click="handleCheckAppealDetail(record)">
                        详情
                    </a>
                </template>
            </a-table>
        </div>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="totalRows"
                show-less-items />
        </div>
        <ReplyAppealModal :visible.sync="replyAppealModalVisible" :id="audittingId" @onReplyAppeal="onReplyAppeal"></ReplyAppealModal>
        <AppealDetailModal :visible.sync="appealDetailModalVisible" :id="appealDetailId"></AppealDetailModal>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue-demi';
import { getGoodFaithAppealTypeColor, getGoodFaithAppealTypeName, goodFaithAppealTypes } from '../../../utils/enums';
import { getAllAppeal } from '../../../api/projectService';
import ReplyAppealModal from './components/replyAppealModal.vue';
import AppealDetailModal from './components/AppealDetailModal.vue';
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        width: 65,
        scopedSlots: { customRender: "index" }
    },
    {
        title: '被扣分人',
        dataIndex: 'deductedUserName'
    },
    {
        title: '扣分条款',
        dataIndex: 'goodFaithClauseTitle'
    },
    {
        title: '诚信分',
        dataIndex: 'grade'
    },
    {
        title: '被扣分人联系电话',
        dataIndex: 'deductedUserPhone'
    },
    {
        title: '扣分人',
        dataIndex: 'aduitUserName'
    },
    {
        title: '扣分时间',
        dataIndex: 'deductedTime',
        scopedSlots: { customRender: "deductedTime" }
    },
    {
        title: '实验编号',
        dataIndex: "experimentNo",
        scopedSlots: { customRender: "experimentNo" }
    },
    {
        title: '实验名称',
        dataIndex: "title",
    },
    {
        title: '状态',
        dataIndex: "appealStatus",
        scopedSlots: { customRender: "appealStatus" }
    },
    {
        title: '操作',
        scopedSlots: { customRender: "operation" }
    }
]

export default {
    props: {},
    setup(props, context) {
        // 项目id
        const projectId = ref(context.root.$route.query.projectId ? context.root.$route.query.projectId : null);
        // 列表
        const list = ref([]);
        onMounted(async () => {
            getAppealList();
        });
        // 
        const current = ref(1);
        const totalRows = ref(0);
        // 申诉流程状态
        const AppealStatus = ref(undefined);
        // 被扣分人名字
        const DeductedUserName = ref("");
        // 扣分人名字
        const AduitUserName = ref("");
        // 实验编号
        const ExperimentNo = ref("");
        const loading = ref(false);
        const handlePageChange = (page, pageSize) => {
            document.documentElement.scrollTop = 0;
            return;
        };
        // 获取所有的申诉记录
        const getAppealList = async () => {
            loading.value = true;
            const res = await getAllAppeal({
                ProjectId: projectId.value,
                PageNum: current.value,
                PageSize: 10,
                AppealStatus: AppealStatus.value,
                DeductedUserName: DeductedUserName.value,
                AduitUserName: AduitUserName.value,
                ExperimentNo: ExperimentNo.value
            });
            if (res.code === 200) {
                list.value = res.data.data;
                totalRows.value = res.data.count;
                loading.value = false;
            }
        };
        const handleSearch = () => {
            current.value = 1;
            getAppealList();
            handlePageChange();
        };
        /*********************************            处理申诉  start           **********************************/
        // 处理申诉对话框
        const replyAppealModalVisible = ref(false);
        // 处理点击
        const handleAppeal = (record) => {
            audittingId.value = record.id;
            replyAppealModalVisible.value = true;
        };
        // 正在处理申诉的id
        const audittingId = ref(null);
        // 处理完成的回调
        const onReplyAppeal = () => {
            getAppealList();
        }
        /*********************************            处理申诉  start           **********************************/
        /*********************************            详情点击 start            **********************************/
        // 详情对话框可见变量
        const appealDetailModalVisible = ref(false);
        // 详情Id
        const appealDetailId = ref(null);
        // 详情点击事件
        const handleCheckAppealDetail = (record) => {
            appealDetailId.value = record.id;
            appealDetailModalVisible.value = true;
        }
        /*********************************            详情点击 end              **********************************/
        return {
            getGoodFaithAppealTypeColor,
            list,
            columns,
            current,
            totalRows,
            handlePageChange,
            AppealStatus,
            DeductedUserName,
            AduitUserName,
            ExperimentNo,
            handleSearch,
            getGoodFaithAppealTypeName,
            loading,
            handleAppeal,
            audittingId,
            replyAppealModalVisible,
            onReplyAppeal,
            handleCheckAppealDetail,
            appealDetailModalVisible,
            appealDetailId,
            goodFaithAppealTypes
        };
    },
    components: { ReplyAppealModal, AppealDetailModal }
}
</script>

<style lang="less" scoped>
.good-faith-audit {
    background: #FFFFFF;
    min-height: calc(100vh - 100px);
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin: 16px;

    .good-faith-audit-title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 16px;
        border-bottom: 1px solid #EBEBEB;
    }

    .search-panel {
        display: flex;
        margin-top: 16px;

        .search-option {
            width: 200px;
        }

        .search-option+.search-option {
            margin-left: 24px;
        }

        .search-operation {
            white-space: nowrap;
            margin-left: 36px;

            .reset-btn {
                background: #eeeeee;
            }

            .ant-btn+.ant-btn {
                margin-left: 8px;
            }
        }
    }

    .table-ctn {
        min-height: calc(100vh - 250px);
        margin-top: 16px;

        /deep/ .ant-table-thead {
            background: #F0F5FF;
        }

        /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
            margin: 0px;
        }

        /deep/ .ant-table-row {
            td {
                padding: 7px;
            }
        }
        .deal-btn {
            color: #FAAD14;
            &:hover{
                opacity: 0.8;
            }
        }
    }

    .default-pagination {   
        text-align: center;
        margin-bottom: 24px;

    }
}
</style>