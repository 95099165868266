<template>
    <a-modal :width="600" title="申诉中" :visible.sync="visible" @cancel="handleCancel" >
        <a-spin :spinning="loading" :delay="500">
        <div class="time-line-ctn">
                <a-timeline style="margin-top: 10px;">
                    <a-timeline-item v-for="(item, index) in timeline" :key="index">
                        <div class="time-line-head">
                            <div class="time-line-title">
                                {{ getTimelineHeader(item) }}
                            </div>
                            <div class="time-line-user">
                                {{ item.userName }}
                            </div>
                            <div class="time-line-date">
                                {{ item.createTime }}
                            </div>
                        </div>
                        <div class="time-line-content">
                            {{ item.context }}
                        </div>
                        <div class="time-line-images" v-if="item.annexURL">
                            <ImageWithPreview v-for="(img, index) in item.annexURL.split(',')" :src="img"></ImageWithPreview>
                       <!--  <ImageWithPreview v-if="item.picOne" :src="`data:image/jpeg;base64,${item.picOne}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picTwo" :src="`data:image/jpeg;base64,${item.picTwo}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picThree" :src="`data:image/jpeg;base64,${item.picThree}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picFour" :src="`data:image/jpeg;base64,${item.picFour}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picFive" :src="`data:image/jpeg;base64,${item.picFive}`">
                        </ImageWithPreview> -->
                        </div>
                    </a-timeline-item>
                </a-timeline>
        </div>
        <a-form-model ref="formRef" :model="formData" size="small" style="width: 99%;margin: 0 auto;" :colon="true"
            :labelCol="{
                xs: { span: 24 },
                sm: { span: 3 },
            }" :wrapper-col="{ span: 21 }" :rules="rules">
            <a-form-model-item label="评审" prop="replyState">
                <a-radio-group v-model="formData.replyState"
                    :options="[{ label: '撤销扣分', value: 1 }, { label: '驳回申诉', value: 2 }]"></a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" prop="replyContent">
                <a-textarea v-model="formData.replyContent" placeholder="请输入" :autoSize="{
                    minRows: 4,
                }" :maxLength="500"></a-textarea>
            </a-form-model-item>
            <a-form-model-item label="凭证" prop="transferVouchers">
                <TransferVoucherUpload v-if="visible" :fileList.sync="formData.transferVouchers" :fileLength="5">
                </TransferVoucherUpload>
            </a-form-model-item>
        </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { watch, ref } from 'vue-demi';
import { getGoodFaithClauseAppealContext, replyGoodFaithAppeal } from '../../../../api/projectService';
import ImageWithPreview from '../../../../components/imageWithPreview.vue';
import TransferVoucherUpload from '../../../projectMember/components/transferVoucherUpload.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        id: {
            type: String | undefined,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const loading = ref(false);
        // 时间线
        const timeline = ref([]);
        watch(() => props.visible, async (value) => {
            if (value) {
                formRef.value && formRef.value.resetFields();
                const res = await getGoodFaithClauseAppealContext({
                    AppealId: props.id
                });
                if (res.code === 200) {
                    timeline.value = res.data;
                }
            }
        });
        // 获取时间线头部
        const getTimelineHeader = (item) => {
            if (item.isReply === 2) {
                return "提交申诉";
            }
            else if (item.isReply === 1 && item.state === 2) {
                return "驳回申诉";
            }
            else if (item.isReply === 1 && item.state === 1) {
                return "已撤销扣分";
            }
        };
        const isSubmitting = ref(false);

        const handleConfirm = async () => {
            isSubmitting.value = true;
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    //return;
                    loading.value = true;
                    const dataForm = new FormData();
                    dataForm.append('GoodFaithAppealId', props.id);
                    dataForm.append('ReplyState',formData.value.replyState);
                    dataForm.append('ReplyContent', formData.value.replyContent);
                    formData.value.transferVouchers && formData.value.transferVouchers.forEach((item) => {
                        dataForm.append('FormFileList', item.file);
                    })
                   /*  const res = await replyGoodFaithAppeal({
                        goodFaithAppealId: props.id,
                        replyState: formData.value.replyState,
                        replyContent: formData.value.replyContent,
                        replyPicOne: formData.value.transferVouchers.length > 0 ? formData.value.transferVouchers[0].thumbUrl : undefined,
                        replyPicTwo: formData.value.transferVouchers.length > 1 ? formData.value.transferVouchers[1].thumbUrl : undefined,
                        replyPicThree: formData.value.transferVouchers.length > 2 ? formData.value.transferVouchers[2].thumbUrl : undefined,
                        replyPicFour: formData.value.transferVouchers.length > 3 ? formData.value.transferVouchers[3].thumbUrl : undefined,
                        replyPicFive: formData.value.transferVouchers.length > 4 ? formData.value.transferVouchers[4].thumbUrl : undefined
                    }) */
                    const res = await replyGoodFaithAppeal(dataForm)
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('onReplyAppeal')
                        context.emit('update:visible', false);
                    }
                    loading.value = false;
                }
                isSubmitting.value = false;
            })
            !formRef.value && (isSubmitting.value = false)
        } 

        const formRef = ref(null);
        const formData = ref({
            replyState: undefined,
            replyContent: '',
            transferVouchers: []
        });
        const rules = ref({
            replyState: [{
                required: true,
                message: '请选择'
            }],
            replyContent: [{
                required: true,
                message: '请输入'
            }]
        });
        return {
            handleCancel,
            loading,
            timeline,
            getTimelineHeader,
            formRef,
            formData,
            rules,
            handleConfirm
        };
    },
    components: { ImageWithPreview, TransferVoucherUpload }
}
</script>

<style lang="less" scoped>
.time-line-ctn {
    max-height: 430px;
    overflow-y: auto;
}

.time-line-head {
    display: flex;

    .time-line-title {
        font-size: 16px;
    }

    .time-line-user {
        margin-left: 40px;
        width: 0px;
        flex-grow: 1;
    }

    .time-line-date {
        margin-left: 50px;
    }
}

.time-line-content {
    line-height: 22px;
}

.time-line-images {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
}

/deep/ .ant-timeline-item-tail {
    border-left: 1px solid @srims-primary-color;
    //margin: 8px 0px;
    height: calc(100% - 18px);
    top: 13px;
}

/deep/ .ant-timeline-item-head-blue {
    border-color: @srims-primary-color;
    background: white;
}

/deep/ .ant-timeline-item-head {
    width: 8px;
    height: 8px;
    left: 1px;
    margin-bottom: 8px 0px;
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>