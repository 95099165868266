<template>
    <a-modal :width="600" title="申诉时间轴" :visible.sync="visible" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="loading" :delay="500">
            <div v-show="loading" style="height: 300px;">
            </div>
            <a-timeline v-if="!loading">
                <a-timeline-item v-for="(item, index) in timeline" :key="index">
                    <div class="time-line-head">
                        <div class="time-line-title">
                            {{ getTimelineHeader(item) }}
                        </div>
                        <div class="time-line-user">
                            {{ item.userName }}
                        </div>
                        <div class="time-line-date">
                            {{ item.createTime }}
                        </div>
                    </div>
                    <div class="time-line-content">
                        {{ item.context }}
                    </div>
                    <div class="time-line-images" v-if="item.annexURL">
                            <ImageWithPreview v-for="(img, index) in item.annexURL.split(',')" :src="img"></ImageWithPreview>
                        </div>
                    <!-- <div class="time-line-images">
                        <ImageWithPreview v-if="item.picOne" :src="`data:image/jpeg;base64,${item.picOne}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picTwo" :src="`data:image/jpeg;base64,${item.picTwo}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picThree" :src="`data:image/jpeg;base64,${item.picThree}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picFour" :src="`data:image/jpeg;base64,${item.picFour}`">
                        </ImageWithPreview>
                        <ImageWithPreview v-if="item.picFive" :src="`data:image/jpeg;base64,${item.picFive}`">
                        </ImageWithPreview>
                    </div> -->
                </a-timeline-item>
            </a-timeline>
        </a-spin>
    </a-modal>
</template>

<script>
import { watch, ref } from 'vue-demi';
import { getGoodFaithClauseAppealContext } from '../../../../api/projectService';
import ImageWithPreview from '../../../../components/imageWithPreview.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        id: {
            type: String | undefined,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const loading = ref(false);
        // 时间线
        const timeline = ref([]);
        watch(() => props.visible, async (value) => {
            if (value) {
                loading.value = true;
                const res = await getGoodFaithClauseAppealContext({
                    AppealId: props.id
                });
                if (res.code === 200) {
                    timeline.value = res.data;
                    loading.value = false
                }
                
            }
        });
        // 获取时间线头部
        const getTimelineHeader = (item) => {
            if (item.isReply === 2) {
                return "提交申诉";
            }
            else if (item.isReply === 1 && item.state === 3) {
                return "驳回申诉";
            }
            else if (item.isReply === 1 && item.state === 2) {
                return "已撤销扣分";
            }
        };
        /* const handleConfirm = async () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    //return;
                    loading.value = true;
                    const res = await appealMyGoodFaithBehavior({
                        GoodFaithBehaviorId: props.GoodFaithBehaviorId,
                        AppealContent: formData.value.AppealContent,
                        AppealPicOne: formData.value.transferVouchers.length > 0 ? formData.value.transferVouchers[0] : undefined,
                        AppealPicTwo: formData.value.transferVouchers.length > 1 ? formData.value.transferVouchers[1] : undefined,
                        AppealPicThree: formData.value.transferVouchers.length > 2 ? formData.value.transferVouchers[2] : undefined,
                        AppealPicFour: formData.value.transferVouchers.length > 3 ? formData.value.transferVouchers[3] : undefined,
                        AppealPicFive: formData.value.transferVouchers.length > 4 ? formData.value.transferVouchers[4] : undefined,
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('onAppeal')
                        context.emit('update:visible', false);
                    }
                    loading.value = false;
                }
            })
        } */
        return {
            handleCancel,
            loading,
            timeline,
            getTimelineHeader
        };
    },
    components: { ImageWithPreview }
}
</script>

<style lang="less" scoped>
.time-line-head {
    display: flex;

    .time-line-title {
        font-size: 16px;
    }

    .time-line-user {
        margin-left: 40px;
        width: 0px;
        flex-grow: 1;
    }

    .time-line-date {
        margin-left: 50px;
    }
}

.time-line-content {
    line-height: 22px;
}

.time-line-images {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
}

/deep/ .ant-timeline-item-tail {
    border-left: 1px solid  @srims-primary-color;
    //margin: 8px 0px;
    height: calc(100% - 18px);
    top: 13px;
}

/deep/ .ant-timeline-item-head-blue {
    border-color:  @srims-primary-color;
    background: white;
}

/deep/ .ant-timeline-item-head {
    width: 8px;
    height: 8px;
    left: 1px;
    margin-bottom: 8px 0px;
}
</style>