<template>
    <div>
        <div class="clearfix">
            <a-upload :customRequest="selfUpload" list-type="picture-card" :file-list.sync="picList" :beforeUpload="beforeUpload"
                @preview="handlePreview" @change="handleChange" @remove="handleRemove" accept="image/*">
                <div v-if="fileList.length < fileLength">
                    <a-icon type="plus" />
                    <!--  <div class="ant-upload-text">
          Upload
        </div> -->
                </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
        </div>
        <div style="color: #FF4D4F;">
            最多上传{{fileLength}}张
        </div>
    </div>
</template>
<script>
import { ref } from 'vue-demi';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default {
    props: {
        fileList: {
            type: Array,
            required: true
        },
        fileLength: {
          type: Number,
          default: 4
        }
    },
    setup(props, context) {
        const previewVisible = ref(false);
        const previewImage = ref('');
        const handleCancel = () => {
            previewVisible.value = false;
        }
        const handlePreview = (file) => {
            previewImage.value = file.thumbUrl;
            previewVisible.value = true;
        }
        const temp = ref(null);
        const beforeUpload = (file, fileList) => {
           return Promise.resolve();
        }
        const handleChange = ({ fileList }) => {
            if(fileList.length < picList.value.length) {
                picList.value = fileList;
                context.emit('update:fileList', picList.value);
            }
        }
        const handleRemove = (file) => {
            //console.log(file)
            picList.value.splice(picList.value.indexOf(file),1);
        }
        const picList = ref([]);
        //自定义上传
        const selfUpload = async ({ action, file, onSuccess, onError, onProgress }) => {
            const res = await getBase64(file);
            picList.value.push({
                name: file.name,
                thumbUrl: res,
                file: file,
                uid: file.uid
            })
            context.emit('update:fileList', picList.value);
        }
        return {
            previewVisible,
            previewImage,
            handleCancel,
            handlePreview,
            beforeUpload,
            handleChange,
            picList,
            selfUpload,
            handleRemove
        }
    }
}
/* export default {
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-2',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-3',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        }
      ],
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    beforeUpload(file,fileList) {
        const { base64 } = useBase64(file);
        
        return Promise.reject();
    }
  },
}; */
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>